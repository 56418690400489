"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var tuicall_engine_webrtc_1 = require("tuicall-engine-webrtc");
var store_1 = require("./store");
var constants_1 = require("./constants");
var aegis_1 = require("./utils/aegis");
/**
 * class TUICallKit
 *
 * TUIGroup 逻辑主体
 */
var TUICallKit = /** @class */ (function () {
    function TUICallKit() {
        this.TUICore = null;
        this.tuiCallEngine = null;
        this.SDKAppID = 0;
    }
    /**
     * init 初始化
     * @param { TUIInitParam } params 初始化参数
     * @param { number } params.SDKAppID
     * @param { string } params.userID
     * @param { string } params.userSig
     * @param { any= } params.tim
     */
    TUICallKit.prototype.init = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var SDKAppID, tim, userID, userSig, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        SDKAppID = params.SDKAppID, tim = params.tim;
                        userID = params.userID, userSig = params.userSig;
                        if (this.TUICore) {
                            SDKAppID = this.TUICore.SDKAppID;
                            tim = this.TUICore.tim;
                        }
                        this.SDKAppID = SDKAppID;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        if (!this.tuiCallEngine) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.tuiCallEngine.destroyInstance()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.tuiCallEngine = tuicall_engine_webrtc_1.TUICallEngine.createInstance({ SDKAppID: SDKAppID, tim: tim });
                        this.bindTIMEvent();
                        return [4 /*yield*/, this.tuiCallEngine.login({ userID: userID, userSig: userSig })];
                    case 4:
                        _a.sent();
                        (0, store_1.updateProfile)(Object.assign(store_1.profile.value, { userID: userID }));
                        console.log("TUICallKit login successful");
                        if (this.TUICore) {
                            aegis_1.default.loginWithTUICoreSuccess(SDKAppID);
                        }
                        else if (!this.TUICore && tim) {
                            aegis_1.default.loginWithTIMSuccess(SDKAppID);
                        }
                        else {
                            aegis_1.default.loginSuccess(SDKAppID);
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        console.error("TUICallKit login failed", JSON.stringify(error_1));
                        if (this.TUICore) {
                            aegis_1.default.loginWithTUICoreFailed(SDKAppID, JSON.stringify(error_1));
                        }
                        else if (!this.TUICore && tim) {
                            aegis_1.default.loginWithTIMFailed(SDKAppID, JSON.stringify(error_1));
                        }
                        else {
                            aegis_1.default.loginFailed(SDKAppID, JSON.stringify(error_1));
                        }
                        throw new Error(error_1);
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * call 呼叫
     * @param { TUICallParam } params 呼叫参数
     * @param { string } params.userID 被呼叫的用户
     * @param { number } params.type 呼叫类型 1-语音，2-视频
     * @param { number= } params.timeout 0为不超时, 单位 s(秒), 默认 30s
     * @param { offlinePushInfoType= } params.offlinePushInfo 自定义离线消息推送
     * @param { string= } params.offlinePushInfo.title 自定义离线消息推送
     * @param { string= } params.offlinePushInfo.description 自定义离线消息推送
     * @param { string= } params.offlinePushInfo.androidOPPOChannelID 自定义离线消息推送
     * @param { string= } params.offlinePushInfo.extension 自定义离线消息推送
     */
    TUICallKit.prototype.call = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var userID, type, timeout, offlinePushInfo, res, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.callingAPIMutex) {
                            return [2 /*return*/];
                        }
                        this.callingAPIMutex = "call";
                        this.checkStatus();
                        userID = params.userID, type = params.type, timeout = params.timeout, offlinePushInfo = params.offlinePushInfo;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.tuiCallEngine.call({ userID: userID, type: type, timeout: timeout, offlinePushInfo: offlinePushInfo })];
                    case 2:
                        res = _a.sent();
                        this.beforeCalling && this.beforeCalling("call");
                        (0, store_1.changeStatus)(constants_1.STATUS.DIALING_C2C);
                        (0, store_1.changeRemoteList)([{ userID: userID, isEntered: false, microphone: false, camera: false }]);
                        (0, store_1.changeCallType)(type);
                        (0, store_1.changeIsFromGroup)(false);
                        aegis_1.default.callSuccess(this.SDKAppID, "call", type === 1 ? "audio" : "video");
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        if (this.error && this.beforeCalling) {
                            this.beforeCalling("call", this.error);
                        }
                        this.error = null;
                        this.callingAPIMutex = "";
                        console.error("TUICallKit call error: " + JSON.stringify(error_2));
                        (0, store_1.changeStatus)(constants_1.STATUS.IDLE);
                        aegis_1.default.callFailed(this.SDKAppID, "call", type === 1 ? "audio" : "video", JSON.stringify(error_2));
                        throw new Error(error_2);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * groupCall 呼叫
     * @param { TUIGroupCallParam } params 呼叫参数
     * @param { string } params.userIDList 被呼叫的用户列表
     * @param { number } params.type 呼叫类型 1-语音，2-视频
     * @param { string } params.groupID 呼叫群组ID
     * @param { number= } params.timeout 0为不超时, 单位 s(秒), 默认 30s
     * @param { offlinePushInfoType= } params.offlinePushInfo 自定义离线消息推送
     * @param { string= } params.offlinePushInfo.title 自定义离线消息推送
     * @param { string= } params.offlinePushInfo.description 自定义离线消息推送
     * @param { string= } params.offlinePushInfo.androidOPPOChannelID 自定义离线消息推送
     * @param { string= } params.offlinePushInfo.extension 自定义离线消息推送
     */
    TUICallKit.prototype.groupCall = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var userIDList, type, groupID, timeout, offlinePushInfo, newRemoteList_1, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.callingAPIMutex) {
                            return [2 /*return*/];
                        }
                        this.callingAPIMutex = "groupCall";
                        this.checkStatus();
                        userIDList = params.userIDList, type = params.type, groupID = params.groupID, timeout = params.timeout, offlinePushInfo = params.offlinePushInfo;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.tuiCallEngine.groupCall({ userIDList: userIDList, type: type, groupID: groupID, timeout: timeout, offlinePushInfo: offlinePushInfo })];
                    case 2:
                        _a.sent();
                        this.beforeCalling && this.beforeCalling("groupCall");
                        newRemoteList_1 = [];
                        userIDList.forEach(function (userID) {
                            newRemoteList_1.push({ userID: userID, isEntered: false, microphone: false, camera: false });
                        });
                        (0, store_1.changeRemoteList)(newRemoteList_1);
                        (0, store_1.changeStatus)(constants_1.STATUS.DIALING_GROUP);
                        (0, store_1.changeCallType)(type);
                        (0, store_1.changeIsFromGroup)(true);
                        aegis_1.default.callSuccess(this.SDKAppID, "groupCall", type === 1 ? "audio" : "video");
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        if (this.error && this.beforeCalling) {
                            this.beforeCalling("groupCall", this.error);
                        }
                        this.error = null;
                        this.callingAPIMutex = "";
                        console.error("TUICallKit groupCall error: " + JSON.stringify(error_3));
                        aegis_1.default.callFailed(this.SDKAppID, "groupCall", type === 1 ? "audio" : "video", JSON.stringify(error_3));
                        throw new Error(error_3);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.checkStatus = function () {
        if (store_1.status.value !== constants_1.STATUS.IDLE) {
            throw new Error((0, store_1.t)('is-already-calling'));
        }
        if (!this.tuiCallEngine) {
            throw new Error((0, store_1.t)('need-init'));
        }
    };
    TUICallKit.prototype.getDeviceList = function (deviceType) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tuiCallEngine.getDeviceList(deviceType)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    TUICallKit.prototype.switchDevice = function (deviceType, deviceId) {
        return __awaiter(this, void 0, void 0, function () {
            var error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.tuiCallEngine.switchDevice({ deviceType: deviceType, deviceId: deviceId })];
                    case 1:
                        _a.sent();
                        this.startLocalView("local");
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.error("TUICallKit switchDevice error", JSON.stringify(error_4));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.bindTUICore = function (TUICore) {
        this.TUICore = TUICore;
    };
    TUICallKit.prototype.setCallback = function (params) {
        var beforeCalling = params.beforeCalling, afterCalling = params.afterCalling, onMinimized = params.onMinimized, onMessageSentByMe = params.onMessageSentByMe;
        beforeCalling && (this.beforeCalling = beforeCalling);
        afterCalling && (this.afterCalling = afterCalling);
        onMinimized && (this.onMinimized = onMinimized);
        onMessageSentByMe && (this.onMessageSentByMe = onMessageSentByMe);
    };
    TUICallKit.prototype.setLanguage = function (language) {
        console.log("TUICallKit change language: ", language);
        (0, store_1.setLanguage)(language);
    };
    TUICallKit.prototype.toggleMinimize = function () {
        console.log("TUICallKit toggleMinimize called", store_1.isMinimized.value, "->", !store_1.isMinimized.value);
        this.onMinimized && this.onMinimized(store_1.isMinimized.value, !store_1.isMinimized.value);
        (0, store_1.changeIsMinimized)(!store_1.isMinimized.value);
        aegis_1.default.MinimizeSuccess(this.SDKAppID);
    };
    TUICallKit.prototype.accept = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.callingAPIMutex === "accept") {
                            return [2 /*return*/];
                        }
                        this.callingAPIMutex = "accept";
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.tuiCallEngine.accept()];
                    case 2:
                        _a.sent();
                        this.getIntoCallingStatus();
                        return [3 /*break*/, 4];
                    case 3:
                        error_5 = _a.sent();
                        console.error("TUICallKit accept error catch: ", error_5);
                        // TODO: 提示无权限
                        (0, store_1.changeStatus)(constants_1.STATUS.IDLE);
                        this.callingAPIMutex = "";
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.reject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.callingAPIMutex === "reject") {
                            return [2 /*return*/];
                        }
                        this.callingAPIMutex = "reject";
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.tuiCallEngine.reject()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_6 = _a.sent();
                        console.error("TUICallKit reject error catch: ", error_6);
                        return [3 /*break*/, 4];
                    case 4:
                        (0, store_1.changeStatus)(constants_1.STATUS.IDLE);
                        this.callingAPIMutex = "";
                        return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.startLocalView = function (local) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tuiCallEngine.startLocalView({ userID: store_1.profile.value.userID, videoViewDomID: local })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.stopLocalView = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tuiCallEngine.stopLocalView({ userID: store_1.profile.value.userID })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.startRemoteView = function (userID) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tuiCallEngine.startRemoteView({ userID: userID, videoViewDomID: userID })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.stopRemoteView = function (userID) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tuiCallEngine.stopRemoteView({ userID: userID })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.hangup = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.callingAPIMutex === "hangup") {
                            return [2 /*return*/];
                        }
                        this.callingAPIMutex = "hangup";
                        return [4 /*yield*/, this.tuiCallEngine.hangup()];
                    case 1:
                        _a.sent();
                        (0, store_1.changeStatus)(constants_1.STATUS.IDLE);
                        this.callingAPIMutex = "";
                        return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.openCamera = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.tuiCallEngine.openCamera()];
                    case 1:
                        _a.sent();
                        (0, store_1.updateProfile)(Object.assign(store_1.profile.value, { camera: true }));
                        return [3 /*break*/, 3];
                    case 2:
                        error_7 = _a.sent();
                        console.error("TUICallKit openCamera error:", error_7);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.closeCamera = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.tuiCallEngine.closeCamera()];
                    case 1:
                        _a.sent();
                        (0, store_1.updateProfile)(Object.assign(store_1.profile.value, { camera: false }));
                        return [3 /*break*/, 3];
                    case 2:
                        error_8 = _a.sent();
                        console.error("TUICallKit closeCamera error:", error_8);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.openMicrophone = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.tuiCallEngine.openMicrophone()];
                    case 1:
                        _a.sent();
                        (0, store_1.updateProfile)(Object.assign(store_1.profile.value, { microphone: true }));
                        return [3 /*break*/, 3];
                    case 2:
                        error_9 = _a.sent();
                        console.error("TUICallKit openMicrophone error:", error_9);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.closeMicrophone = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.tuiCallEngine.closeMicrophone()];
                    case 1:
                        _a.sent();
                        (0, store_1.updateProfile)(Object.assign(store_1.profile.value, { microphone: false }));
                        return [3 /*break*/, 3];
                    case 2:
                        error_10 = _a.sent();
                        console.error("TUICallKit closeMicrophone error:", error_10);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.setVideoQuality = function (profile) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tuiCallEngine.setVideoQuality(profile)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.switchCallMediaType = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_11;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("TUICallKit switchCallMediaType", tuicall_engine_webrtc_1.TUICallType.AUDIO_CALL);
                        if (this.callingAPIMutex === "switchCallMediaType") {
                            return [2 /*return*/];
                        }
                        this.callingAPIMutex = "switchCallMediaType";
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.tuiCallEngine.switchCallMediaType(tuicall_engine_webrtc_1.TUICallType.AUDIO_CALL)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_11 = _a.sent();
                        console.error("TUICallKit switchCallMediaType error:", error_11);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.onStatusChanged = function (statusChanged) {
        this.statusChanged = statusChanged;
    };
    /**
     * 组件销毁
     */
    TUICallKit.prototype.destroyed = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_12;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.unbindTIMEvent();
                        (0, store_1.changeStatus)(constants_1.STATUS.IDLE);
                        this.callingAPIMutex = '';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        if (!this.tuiCallEngine) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.tuiCallEngine.destroyInstance()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        console.log("TUICallKit destroyInstance success");
                        return [3 /*break*/, 5];
                    case 4:
                        error_12 = _a.sent();
                        console.error("TUICallKit destroyed error:", error_12);
                        throw new Error(error_12);
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    TUICallKit.prototype.getIntoCallingStatus = function () {
        if (!store_1.isFromGroup.value && store_1.callType.value === constants_1.CALL_TYPE_STRING.AUDIO)
            (0, store_1.changeStatus)(constants_1.STATUS.CALLING_C2C_AUDIO);
        if (!store_1.isFromGroup.value && store_1.callType.value === constants_1.CALL_TYPE_STRING.VIDEO)
            (0, store_1.changeStatus)(constants_1.STATUS.CALLING_C2C_VIDEO);
        if (store_1.isFromGroup.value && store_1.callType.value === constants_1.CALL_TYPE_STRING.AUDIO)
            (0, store_1.changeStatus)(constants_1.STATUS.CALLING_GROUP_AUDIO);
        if (store_1.isFromGroup.value && store_1.callType.value === constants_1.CALL_TYPE_STRING.VIDEO)
            (0, store_1.changeStatus)(constants_1.STATUS.CALLING_GROUP_VIDEO);
    };
    /**
     * /////////////////////////////////////////////////////////////////////////////////
     * //
     * //                                    TUICallEngine 事件监听注册接口
     * //
     * /////////////////////////////////////////////////////////////////////////////////
     */
    TUICallKit.prototype.bindTIMEvent = function () {
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.ERROR, this.handleError, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.SDK_READY, this.handleSDKReady, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.INVITED, this.handleInvited, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.USER_ACCEPT, this.handleUserAccept, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.USER_ENTER, this.handleUserEnter, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.USER_LEAVE, this.handleUserLeave, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.REJECT, this.handleReject, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.NO_RESP, this.handleNoResponse, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.LINE_BUSY, this.handleLineBusy, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.CALLING_CANCEL, this.handleCallingCancel, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.KICKED_OUT, this.handleKickedOut, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.CALLING_TIMEOUT, this.handleCallingTimeOut, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.CALLING_END, this.handleCallingEnd, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.USER_VIDEO_AVAILABLE, this.handleUserVideoAvailable, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.USER_AUDIO_AVAILABLE, this.handleUserAudioAvailable, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.USER_VOICE_VOLUME, this.handleUserVoiceVolume, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.CALL_TYPE_CHANGED, this.handleCallTypeChanged, this);
        this.tuiCallEngine.on(tuicall_engine_webrtc_1.TUICallEvent.MESSAGE_SENT_BY_ME, this.handleMessageSentByMe, this);
    };
    TUICallKit.prototype.unbindTIMEvent = function () {
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.ERROR, this.handleError, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.SDK_READY, this.handleSDKReady, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.INVITED, this.handleInvited);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.USER_ACCEPT, this.handleUserAccept);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.USER_ENTER, this.handleUserEnter, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.USER_LEAVE, this.handleUserLeave, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.REJECT, this.handleReject, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.NO_RESP, this.handleNoResponse, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.LINE_BUSY, this.handleLineBusy, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.CALLING_CANCEL, this.handleCallingCancel, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.KICKED_OUT, this.handleKickedOut, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.CALLING_TIMEOUT, this.handleCallingTimeOut, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.CALLING_END, this.handleCallingEnd, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.USER_VIDEO_AVAILABLE, this.handleUserVideoAvailable, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.USER_AUDIO_AVAILABLE, this.handleUserAudioAvailable, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.USER_VOICE_VOLUME, this.handleUserVoiceVolume, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.CALL_TYPE_CHANGED, this.handleCallTypeChanged, this);
        this.tuiCallEngine.off(tuicall_engine_webrtc_1.TUICallEvent.MESSAGE_SENT_BY_ME, this.handleMessageSentByMe, this);
    };
    TUICallKit.prototype.handleError = function (event) {
        var code = event.code;
        this.error = {
            code: code,
            type: "",
            message: ""
        };
        switch (code) {
            case 60001:
                this.error.type = (0, store_1.t)('method-call-failed');
                this.error.message = "switchToAudioCall ".concat((0, store_1.t)('call-failed'));
                break;
            case 60002:
                this.error.type = (0, store_1.t)('method-call-failed');
                this.error.message = "switchToVideoCall ".concat((0, store_1.t)('call-failed'));
                break;
            case 60003:
                this.error.type = (0, store_1.t)('failed-to-obtain-permission');
                this.error.message = (0, store_1.t)('microphone-unavailable');
                break;
            case 60004:
                this.error.type = (0, store_1.t)('failed-to-obtain-permission');
                this.error.message = (0, store_1.t)('camera-unavailable');
                break;
            case 60005:
                this.error.type = (0, store_1.t)('failed-to-obtain-permission');
                this.error.message = (0, store_1.t)('ban-device');
                break;
            case 60006:
                this.error.type = (0, store_1.t)('environment-detection-failed');
                this.error.message = (0, store_1.t)('not-supported-webrtc');
                break;
        }
        console.error("TUICallKit Error", JSON.stringify(this.error));
        if (store_1.status.value === constants_1.STATUS.BE_INVITED) {
            this.beforeCalling && this.beforeCalling("invited", this.error);
        }
        (0, store_1.changeStatus)(constants_1.STATUS.IDLE);
    };
    TUICallKit.prototype.handleSDKReady = function (event) {
        console.log("TUICallKit SDK is ready.", event);
    };
    TUICallKit.prototype.handleKickedOut = function () {
        console.error("TUICallKit Kicked Out", JSON.stringify(event));
    };
    TUICallKit.prototype.handleUserVideoAvailable = function (event) {
        var userID = event.userID, isVideoAvailable = event.isVideoAvailable;
        (0, store_1.changeRemoteDeviceByUserID)(userID, constants_1.CALL_TYPE_STRING.VIDEO, isVideoAvailable);
    };
    TUICallKit.prototype.handleUserAudioAvailable = function (event) {
        var userID = event.userID, isAudioAvailable = event.isAudioAvailable;
        (0, store_1.changeRemoteDeviceByUserID)(userID, constants_1.CALL_TYPE_STRING.AUDIO, isAudioAvailable);
    };
    TUICallKit.prototype.handleUserVoiceVolume = function (event) {
        var volumeMap = event.volumeMap;
        (0, store_1.updateRemoteVolumeMap)(volumeMap);
    };
    TUICallKit.prototype.handleInvited = function (event) {
        console.log("TUICallKit handleInvited", event);
        this.beforeCalling && this.beforeCalling("invited", this.error);
        if (this.error) {
            this.reject();
            this.error = null;
            return;
        }
        var sponsor = event.sponsor, isFromGroup = event.isFromGroup, inviteData = event.inviteData;
        var callType = inviteData.callType;
        (0, store_1.changeStatus)(constants_1.STATUS.BE_INVITED);
        this.callingAPIMutex = 'be_invited';
        (0, store_1.changeRemoteList)([{ userID: sponsor, isEntered: false }]);
        (0, store_1.changeCallType)(callType);
        (0, store_1.changeIsFromGroup)(isFromGroup);
    };
    TUICallKit.prototype.handleUserAccept = function (event) {
        console.log("TUICallKit handleUserAccept", event);
        var userID = event.userID;
        if (store_1.status.value === constants_1.STATUS.BE_INVITED) {
            console.log("TUICallKit userID=".concat(userID, " accept the call"));
            return;
        }
        this.getIntoCallingStatus();
    };
    TUICallKit.prototype.handleUserEnter = function (event) {
        console.log("TUICallKit handleUserEnter", event);
        var userID = event.userID;
        (0, store_1.addRemoteListByUserID)(userID);
    };
    TUICallKit.prototype.handleUserLeave = function (event) {
        console.log("TUICallKit handleUserLeave", event);
        var userID = event.userID;
        (0, store_1.removeRemoteListByUserID)(userID);
    };
    TUICallKit.prototype.handleReject = function (event) {
        console.log("TUICallKit handleReject", event);
        var userID = event.userID;
        if (store_1.status.value === constants_1.STATUS.BE_INVITED)
            return;
        if (store_1.status.value === constants_1.STATUS.CALLING_GROUP_AUDIO || store_1.status.value === constants_1.STATUS.CALLING_GROUP_VIDEO || store_1.status.value === constants_1.STATUS.DIALING_GROUP) {
            (0, store_1.removeRemoteListByUserID)(userID);
            return;
        }
        (0, store_1.changeStatus)(constants_1.STATUS.IDLE, constants_1.CHANGE_STATUS_REASON.REJECT, 1000);
        this.callingAPIMutex = '';
    };
    TUICallKit.prototype.handleNoResponse = function (event) {
        var _this = this;
        console.log("TUICallKit handleNoResponse", event);
        var userIDList = event.userIDList;
        userIDList.forEach(function (userID) {
            if ((0, store_1.removeRemoteListByUserID)(userID) <= 0) {
                (0, store_1.changeStatus)(constants_1.STATUS.IDLE, constants_1.CHANGE_STATUS_REASON.NO_RESPONSE, store_1.isFromGroup.value ? 0 : 1000);
                _this.callingAPIMutex = '';
            }
        });
    };
    TUICallKit.prototype.handleLineBusy = function (event) {
        console.log("TUICallKit handleLineBusy", event);
        var userID = event.userID;
        if ((0, store_1.removeRemoteListByUserID)(userID) <= 0) {
            (0, store_1.changeStatus)(constants_1.STATUS.IDLE, constants_1.CHANGE_STATUS_REASON.LINE_BUSY, store_1.isFromGroup.value ? 0 : 1000);
        }
        this.callingAPIMutex = '';
    };
    TUICallKit.prototype.handleCallingCancel = function (event) {
        console.log("TUICallKit handleCallingCancel", event);
        (0, store_1.changeStatus)(constants_1.STATUS.IDLE, constants_1.CHANGE_STATUS_REASON.CALLING_CANCEL, 1000);
        this.callingAPIMutex = '';
    };
    TUICallKit.prototype.handleCallingTimeOut = function (event) {
        var _this = this;
        console.log("TUICallKit handleCallingTimeOut", event);
        var userIDList = event.userIDList;
        userIDList.forEach(function (userID) {
            if ((0, store_1.removeRemoteListByUserID)(userID) <= 0 || userID === store_1.profile.value.userID) {
                (0, store_1.changeStatus)(constants_1.STATUS.IDLE, constants_1.CHANGE_STATUS_REASON.CALLING_TIMEOUT, store_1.isFromGroup.value ? 0 : 1000);
                _this.callingAPIMutex = '';
            }
        });
    };
    TUICallKit.prototype.handleCallingEnd = function (event) {
        console.log("TUICallKit handleCallingEnd", event);
        (0, store_1.changeStatus)(constants_1.STATUS.IDLE);
        this.callingAPIMutex = '';
    };
    TUICallKit.prototype.handleCallTypeChanged = function (event) {
        console.log("TUICallKit handleCallTypeChanged", event);
        var newCallType = event.newCallType;
        (0, store_1.changeCallType)(newCallType);
    };
    TUICallKit.prototype.handleMessageSentByMe = function (event) {
        var message = event === null || event === void 0 ? void 0 : event.data;
        console.log('TUICallKit MessageSentByMe', message);
        this.onMessageSentByMe && this.onMessageSentByMe(message);
    };
    return TUICallKit;
}());
exports.default = TUICallKit;
