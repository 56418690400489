"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "calling-wrapper" };
var _hoisted_2 = { class: "tag" };
var _hoisted_3 = { class: "microphone-icon-container" };
var _hoisted_4 = ["id"];
var _hoisted_5 = { class: "tag" };
var _hoisted_6 = { class: "microphone-icon-container" };
var index_1 = require("../index");
var vue_3 = require("vue");
var index_2 = require("../store/index");
var MicrophoneIcon_vue_1 = require("./MicrophoneIcon.vue");
var switch2_vue_1 = require("../icons/switch2.vue");
var microphoneClosed_vue_1 = require("../icons/microphoneClosed.vue");
var utils_1 = require("../utils");
require("../style.css");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Calling-C2CVideo',
    setup: function (__props) {
        var _this = this;
        (0, vue_3.onMounted)(function () {
            refreshUserViewList();
        });
        (0, vue_3.onUpdated)(function () {
            refreshUserViewList();
        });
        var refreshUserViewList = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, vue_3.nextTick)()];
                    case 1:
                        _a.sent();
                        if (index_2.remoteList.value.length >= 1) {
                            index_1.TUICallKitServer.startLocalView('local');
                            index_1.TUICallKitServer.startRemoteView(index_2.remoteList.value[0].userID);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var localClass = (0, vue_3.ref)('small');
        var localClassH5 = (0, vue_3.ref)('small-h5');
        var remoteClass = (0, vue_3.ref)('large');
        var switchUserView = function () { return __awaiter(_this, void 0, void 0, function () {
            var largeView, smallView;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!utils_1.isMobile) return [3 /*break*/, 1];
                        _a = [remoteClass.value, localClassH5.value], localClassH5.value = _a[0], remoteClass.value = _a[1];
                        return [3 /*break*/, 3];
                    case 1:
                        _b = [remoteClass.value, localClass.value], localClass.value = _b[0], remoteClass.value = _b[1];
                        return [4 /*yield*/, (0, vue_3.nextTick)()];
                    case 2:
                        _c.sent();
                        largeView = document.getElementsByClassName('large')[0];
                        smallView = document.getElementsByClassName('small')[0];
                        smallView.style.height = "170px";
                        smallView.style.width = "260px";
                        largeView.style.height = "100%";
                        largeView.style.width = "100%";
                        _c.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var h5SwitchUserView = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!utils_1.isMobile)
                    return [2 /*return*/];
                switchUserView();
                return [2 /*return*/];
            });
        }); };
        return function (_ctx, _cache) {
            var _a, _b, _c;
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", {
                    id: "local",
                    class: (0, vue_2.normalizeClass)((0, vue_2.unref)(utils_1.isMobile) ? localClassH5.value : localClass.value),
                    onClick: h5SwitchUserView
                }, [
                    (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("span", _hoisted_2, [
                        (0, vue_2.createElementVNode)("div", _hoisted_3, [
                            ((_a = (0, vue_2.unref)(index_2.profile)) === null || _a === void 0 ? void 0 : _a.microphone)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(MicrophoneIcon_vue_1.default, {
                                    key: 0,
                                    volume: (_b = (0, vue_2.unref)(index_2.profile)) === null || _b === void 0 ? void 0 : _b.volume
                                }, null, 8, ["volume"]))
                                : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(microphoneClosed_vue_1.default, { key: 1 }))
                        ]),
                        (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)("".concat((0, vue_2.unref)(index_2.profile).userID, " ").concat((0, vue_2.unref)(index_2.t)('me'))), 1)
                    ], 512), [
                        [vue_2.vShow, !(0, vue_2.unref)(utils_1.isMobile)]
                    ]),
                    (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("div", {
                        class: "switch-large-small",
                        onClick: switchUserView
                    }, [
                        (0, vue_2.createVNode)(switch2_vue_1.default)
                    ], 512), [
                        [vue_2.vShow, !(0, vue_2.unref)(utils_1.isMobile)]
                    ])
                ], 2),
                ((0, vue_2.unref)(index_2.remoteList).length >= 1)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                        key: 0,
                        id: (0, vue_2.unref)(index_2.remoteList)[0].userID,
                        class: (0, vue_2.normalizeClass)(remoteClass.value),
                        onClick: h5SwitchUserView
                    }, [
                        (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("span", _hoisted_5, [
                            (0, vue_2.createElementVNode)("div", _hoisted_6, [
                                ((_c = (0, vue_2.unref)(index_2.remoteList)[0]) === null || _c === void 0 ? void 0 : _c.microphone)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(MicrophoneIcon_vue_1.default, {
                                        key: 0,
                                        volume: (0, vue_2.unref)(index_2.remoteList)[0].volume
                                    }, null, 8, ["volume"]))
                                    : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(microphoneClosed_vue_1.default, { key: 1 }))
                            ]),
                            (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)((0, vue_2.unref)(index_2.remoteList)[0].userID), 1)
                        ], 512), [
                            [vue_2.vShow, !(0, vue_2.unref)(utils_1.isMobile)]
                        ]),
                        (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("div", {
                            class: "switch-large-small",
                            onClick: switchUserView
                        }, [
                            (0, vue_2.createVNode)(switch2_vue_1.default)
                        ], 512), [
                            [vue_2.vShow, !(0, vue_2.unref)(utils_1.isMobile)]
                        ])
                    ], 10, _hoisted_4))
                    : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
