"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    key: 0,
    class: "float-control-panel"
};
var _hoisted_2 = { class: "float-control-item-icon" };
var _hoisted_3 = { class: "float-control-item-icon" };
var _hoisted_4 = { class: "float-control-item-icon" };
var store_1 = require("../store");
var constants_1 = require("../constants");
var index_1 = require("../index");
var floatCallEnd_vue_1 = require("../icons/floatCallEnd.vue");
var floatMicrophone_vue_1 = require("../icons/floatMicrophone.vue");
var floatMicrophoneClosed_vue_1 = require("../icons/floatMicrophoneClosed.vue");
var floatFullScreen_vue_1 = require("../icons/floatFullScreen.vue");
require("../style.css");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TUICallKitMini',
    setup: function (__props) {
        var _this = this;
        function toggleMinimize() {
            index_1.TUICallKitServer.toggleMinimize();
        }
        var hangup = function () {
            index_1.TUICallKitServer.hangup();
        };
        var toggleMicrophone = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!((_a = store_1.profile.value) === null || _a === void 0 ? void 0 : _a.microphone)) return [3 /*break*/, 2];
                        return [4 /*yield*/, index_1.TUICallKitServer.closeMicrophone()];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, index_1.TUICallKitServer.openMicrophone()];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return function (_ctx, _cache) {
            var _a, _b;
            return ((0, vue_2.unref)(store_1.status) !== (0, vue_2.unref)(constants_1.STATUS).IDLE)
                ? (0, vue_2.withDirectives)(((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                    (0, vue_2.createElementVNode)("div", _hoisted_2, [
                        (0, vue_2.createElementVNode)("div", {
                            class: "float-control-item-icon-container",
                            onClick: hangup
                        }, [
                            (0, vue_2.createVNode)(floatCallEnd_vue_1.default)
                        ])
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        (0, vue_2.createElementVNode)("div", {
                            class: "float-control-item-icon-container",
                            onClick: toggleMicrophone
                        }, [
                            ((_a = (0, vue_2.unref)(store_1.profile)) === null || _a === void 0 ? void 0 : _a.microphone)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(floatMicrophone_vue_1.default, { key: 0 }))
                                : (0, vue_2.createCommentVNode)("", true),
                            (!((_b = (0, vue_2.unref)(store_1.profile)) === null || _b === void 0 ? void 0 : _b.microphone))
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(floatMicrophoneClosed_vue_1.default, { key: 1 }))
                                : (0, vue_2.createCommentVNode)("", true)
                        ])
                    ]),
                    (0, vue_2.createElementVNode)("div", _hoisted_4, [
                        (0, vue_2.createElementVNode)("div", {
                            class: "float-control-item-icon-container",
                            onClick: toggleMinimize
                        }, [
                            (0, vue_2.createVNode)(floatFullScreen_vue_1.default)
                        ])
                    ])
                ], 512)), [
                    [vue_2.vShow, (0, vue_2.unref)(store_1.isMinimized)]
                ])
                : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
