"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "calling-wrapper" };
var _hoisted_2 = { class: "turn-icon-container" };
var _hoisted_3 = { class: "turn-icon-container" };
var _hoisted_4 = { class: "tag" };
var _hoisted_5 = { class: "microphone-icon-container" };
var _hoisted_6 = ["id"];
var _hoisted_7 = {
    key: 0,
    class: "user-view-text-container"
};
var _hoisted_8 = { class: "user-view-info" };
var _hoisted_9 = { class: "tag" };
var _hoisted_10 = { class: "microphone-icon-container" };
var index_1 = require("../store/index");
var vue_3 = require("vue");
var MicrophoneIcon_vue_1 = require("./MicrophoneIcon.vue");
var index_2 = require("../index");
var constants_1 = require("../constants");
var left_vue_1 = require("../icons/left.vue");
var right_vue_1 = require("../icons/right.vue");
var microphoneClosed_vue_1 = require("../icons/microphoneClosed.vue");
require("../style.css");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Calling-Group',
    setup: function (__props) {
        var _this = this;
        var currentPage = (0, vue_3.ref)(1);
        var currentPageRemoteList = (0, vue_3.ref)([]);
        var groupUserViewClass = (0, vue_3.ref)("group-user-view");
        var groupCallingContainerClass = (0, vue_3.ref)("group-calling-container");
        var userViewUserId = (0, vue_3.ref)("user-view-user-id");
        (0, vue_3.watch)(currentPageRemoteList, function () { return __awaiter(_this, void 0, void 0, function () {
            var userViewCount;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userViewCount = currentPageRemoteList.value.length + 1;
                        return [4 /*yield*/, (0, vue_3.nextTick)()];
                    case 1:
                        _a.sent();
                        groupUserViewClass.value = "group-user-view group-user-view-".concat(userViewCount);
                        groupCallingContainerClass.value = "group-calling-container group-calling-container-".concat(userViewCount);
                        userViewUserId.value = "user-view-user-id user-view-user-id-".concat(userViewCount);
                        return [2 /*return*/];
                }
            });
        }); });
        (0, vue_3.watchEffect)(function () {
            refreshCurrentPageRemoteList();
        });
        (0, vue_3.onUpdated)(function () {
            if (index_1.status.value === constants_1.STATUS.DIALING_GROUP)
                return;
            renderUserView();
        });
        function refreshCurrentPageRemoteList() {
            var newPageList = [];
            for (var i = (currentPage.value - 1) * 8; i < (currentPage.value) * 8 && i < index_1.remoteList.value.length; i++) {
                newPageList.push(index_1.remoteList.value[i]);
            }
            currentPageRemoteList.value = newPageList;
        }
        function renderUserView() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, vue_3.nextTick)()];
                        case 1:
                            _a.sent();
                            index_2.TUICallKitServer.startLocalView('local');
                            currentPageRemoteList.value.forEach(function (remoteUserItem) {
                                if (remoteUserItem.isEntered) {
                                    index_2.TUICallKitServer.startRemoteView(remoteUserItem.userID);
                                }
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        function pageReduce() {
            if (currentPage.value > 1) {
                currentPage.value--;
            }
        }
        function pageIncrease() {
            if (currentPage.value < Math.floor((index_1.remoteList.value.length - 1) / 8 + 1)) {
                currentPage.value++;
            }
        }
        return function (_ctx, _cache) {
            var _a, _b;
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                ((0, vue_2.unref)(index_1.remoteList).length > 8)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 0 }, [
                        (0, vue_2.createElementVNode)("div", {
                            class: "page-turn left",
                            onClick: pageReduce
                        }, [
                            (0, vue_2.createElementVNode)("div", _hoisted_2, [
                                (0, vue_2.createVNode)(left_vue_1.default)
                            ])
                        ]),
                        (0, vue_2.createElementVNode)("div", {
                            class: "page-turn right",
                            onClick: pageIncrease
                        }, [
                            (0, vue_2.createElementVNode)("div", _hoisted_3, [
                                (0, vue_2.createVNode)(right_vue_1.default)
                            ])
                        ])
                    ], 64))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createElementVNode)("div", {
                    class: (0, vue_2.normalizeClass)(groupCallingContainerClass.value)
                }, [
                    (0, vue_2.createElementVNode)("div", {
                        id: "local",
                        class: (0, vue_2.normalizeClass)(groupUserViewClass.value)
                    }, [
                        (0, vue_2.createElementVNode)("span", _hoisted_4, [
                            (0, vue_2.createElementVNode)("div", _hoisted_5, [
                                ((_a = (0, vue_2.unref)(index_1.profile)) === null || _a === void 0 ? void 0 : _a.microphone)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(MicrophoneIcon_vue_1.default, {
                                        key: 0,
                                        volume: (_b = (0, vue_2.unref)(index_1.profile)) === null || _b === void 0 ? void 0 : _b.volume
                                    }, null, 8, ["volume"]))
                                    : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(microphoneClosed_vue_1.default, { key: 1 }))
                            ]),
                            (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)("".concat((0, vue_2.unref)(index_1.profile).userID, " ").concat((0, vue_2.unref)(index_1.t)('me'))), 1)
                        ])
                    ], 2),
                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(currentPageRemoteList.value, function (remoteUserItem) {
                        return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                            key: remoteUserItem.userID,
                            class: (0, vue_2.normalizeClass)(groupUserViewClass.value),
                            id: remoteUserItem.userID
                        }, [
                            (!remoteUserItem.isEntered || ((0, vue_2.unref)(index_1.isFromGroup) && (0, vue_2.unref)(index_1.callType) === (0, vue_2.unref)(constants_1.CALL_TYPE_STRING).AUDIO))
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_7, [
                                    (0, vue_2.createElementVNode)("div", {
                                        class: (0, vue_2.normalizeClass)(userViewUserId.value)
                                    }, (0, vue_2.toDisplayString)(remoteUserItem.userID), 3),
                                    (0, vue_2.createElementVNode)("div", _hoisted_8, (0, vue_2.toDisplayString)(remoteUserItem.isEntered ? (0, vue_2.unref)(index_1.t)('already-enter') : (0, vue_2.unref)(index_1.t)('waiting')), 1)
                                ]))
                                : (0, vue_2.createCommentVNode)("", true),
                            (0, vue_2.createElementVNode)("span", _hoisted_9, [
                                (0, vue_2.createElementVNode)("div", _hoisted_10, [
                                    (remoteUserItem === null || remoteUserItem === void 0 ? void 0 : remoteUserItem.microphone)
                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(MicrophoneIcon_vue_1.default, {
                                            key: 0,
                                            volume: remoteUserItem === null || remoteUserItem === void 0 ? void 0 : remoteUserItem.volume
                                        }, null, 8, ["volume"]))
                                        : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(microphoneClosed_vue_1.default, { key: 1 }))
                                ]),
                                (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(remoteUserItem.userID), 1)
                            ])
                        ], 10, _hoisted_6));
                    }), 128))
                ], 2)
            ]));
        };
    }
});
