"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATUS = exports.TUICallKitServer = exports.TUICallKitMini = exports.TUICallKit = void 0;
var server_1 = require("./server");
var TUICallKit_vue_1 = require("./components/TUICallKit.vue");
exports.TUICallKit = TUICallKit_vue_1.default;
var TUICallKitMini_vue_1 = require("./components/TUICallKitMini.vue");
exports.TUICallKitMini = TUICallKitMini_vue_1.default;
var constants_1 = require("./constants");
Object.defineProperty(exports, "STATUS", { enumerable: true, get: function () { return constants_1.STATUS; } });
var TUICallKitServer = new server_1.default();
exports.TUICallKitServer = TUICallKitServer;
var plugin = function (TUICore) {
    TUICore.component('TUICallKit', { server: TUICallKitServer });
    TUICore.component('TUICallKitMini', { server: TUICallKitServer });
    TUICallKitServer.bindTUICore(TUICore);
    return TUICallKit_vue_1.default;
};
var install = function (app) {
    app.component('TUICallKitMini', TUICallKitMini_vue_1.default);
    app.component('TUICallKit', TUICallKit_vue_1.default);
    console.log("TUICallKit&mini installed", app);
};
TUICallKit_vue_1.default.plugin = plugin;
TUICallKit_vue_1.default.install = install;
