"use strict";
// const en = require('./lang/en.json');
// const zh = require('./lang/zh.json');
Object.defineProperty(exports, "__esModule", { value: true });
var languageData = {
    en: {
        "call": "call",
        "video-call": "video call",
        "audio-call": "audio call",
        "search": "search",
        "search-result": "search result",
        "no-user": "user not found",
        "member-not-added": "member not added",
        "input-phone-userID": "phone number or userID",
        "not-login": "not logged in",
        "login-status-expire": "login status is invalid, please refresh the page and try again",
        "experience-multi-call": "experience multi-person calls, please download the full-featured demo: ",
        "not-support-multi-call": "multi-person call interface is not open",
        "userID": "userID",
        "already-enter": "entered the call",
        "waiting": "Calling...",
        "hangup": "Hang up",
        "reject": "Decline",
        "accept": "Accept",
        "camera-opened": "Camera on",
        "camera-closed": "Camera off",
        "microphone-opened": "Mic on",
        "microphone-closed": "Mic off",
        "camera": "Camera",
        "microphone": "Microphone",
        "image-resolution": "Resolution",
        "default-image-resolution": "Default",
        "invited-person": "Invite",
        "video-to-audio": "Switch to audio",
        "me": "(me)",
        "start-voice-call": "Audio call from ",
        "start-video-call": "Video call from ",
        "be-rejected": "Call declined, ",
        "be-no-response": "No response, ",
        "be-line-busy": "Line busy, ",
        "be-canceled": "The call is canceled, ",
        "timeout": "The call timed out",
        "voice-call-end": "Voice call ended",
        "video-call-end": "Video call ended",
        "method-call-failed": "Failed to sync the operation",
        "failed-to-obtain-permission": "Failed to obtain permissions",
        "environment-detection-failed": "Failed to check the environment",
        "call-failed": "call method failed",
        "microphone-unavailable": "No mic found",
        "ban-device": "Device access denied",
        "not-supported-webrtc": "Your current environment does not support WebRTC",
        "is-already-calling": "TUICallKit is already on a call",
        "need-init": "TUICallKit initialization is required before initiating a call",
        "can't call yourself": "Can't call yourself"
    },
    "zh-cn": {
        "call": "通话",
        "video-call": "视频通话",
        "audio-call": "音频通话",
        "search": "搜索",
        "search-result": "搜索结果",
        "no-user": "未搜索到用户",
        "member-not-added": "未添加成员",
        "not-login": "未登录",
        "login-status-expire": "登录状态已失效，请刷新网页重试",
        "experience-multi-call": "体验多人通话请下载全功能demo:",
        "not-support-multi-call": "多人通话接口未开放",
        "input-phone-userID": "请输入手机号/用户ID",
        "userID": "用户ID",
        "already-enter": "已经进入当前通话",
        "waiting": "等待接听...",
        "hangup": "挂断",
        "reject": "拒绝",
        "accept": "接受",
        "camera-opened": "摄像头已开",
        "camera-closed": "摄像头已关",
        "microphone-opened": "麦克风已开",
        "microphone-closed": "麦克风已关",
        "camera": "摄像头",
        "microphone": "麦克风",
        "image-resolution": "分辨率",
        "default-image-resolution": "默认分辨率",
        "invited-person": "添加成员",
        "video-to-audio": "视频转语音",
        "me": "（我）",
        "start-voice-call": "发起的语音通话",
        "start-video-call": "发起的视频通话",
        "be-rejected": "对方已拒绝，",
        "be-no-response": "对方无应答，",
        "be-line-busy": "对方忙线中，",
        "be-canceled": "对方已取消",
        "timeout": "本次通话超时未应答",
        "voice-call-end": "语音通话结束",
        "video-call-end": "视频通话结束",
        "method-call-failed": "同步操作失败",
        "failed-to-obtain-permission": "权限获取失败",
        "environment-detection-failed": "环境检测失败",
        "call-failed": "调用失败",
        "microphone-unavailable": "没有可用的麦克风设备",
        "ban-device": "用户禁止使用设备",
        "not-supported-webrtc": "当前环境不支持 webRTC",
        "is-already-calling": "TUICallKit 已在通话状态",
        "need-init": "TUICallKit 发起通话前需先进行初始化",
        "can't call yourself": "不能呼叫自己"
    }
};
exports.default = languageData;
