"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    key: 0,
    class: "tui-call-kit",
    id: "tui-call-kit-id"
};
var _hoisted_2 = { class: "function-buttons" };
var Dialing_vue_1 = require("./Dialing.vue");
var ControlPanel_vue_1 = require("./ControlPanel.vue");
var Calling_Group_vue_1 = require("./Calling-Group.vue");
var Calling_C2CVideo_vue_1 = require("./Calling-C2CVideo.vue");
var store_1 = require("../store");
var constants_1 = require("../constants");
var index_1 = require("../index");
var minimize_vue_1 = require("../icons/minimize.vue");
var fullScreen_vue_1 = require("../icons/fullScreen.vue");
var vue_3 = require("vue");
require("../style.css");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TUICallKit',
    props: {
        beforeCalling: null,
        afterCalling: null,
        onMinimized: null,
        onMessageSentByMe: null,
        allowedMinimized: { type: Boolean, default: false },
        allowedFullScreen: { type: Boolean, default: true },
        lang: { default: "zh-cn" }
    },
    setup: function (__props) {
        var props = __props;
        var _a = (0, vue_3.toRefs)(props), beforeCalling = _a.beforeCalling, afterCalling = _a.afterCalling, onMinimized = _a.onMinimized, onMessageSentByMe = _a.onMessageSentByMe, allowedMinimized = _a.allowedMinimized, allowedFullScreen = _a.allowedFullScreen, lang = _a.lang;
        index_1.TUICallKitServer.setCallback({
            beforeCalling: beforeCalling && beforeCalling.value,
            afterCalling: afterCalling && afterCalling.value,
            onMinimized: onMinimized && onMinimized.value,
            onMessageSentByMe: onMessageSentByMe && onMessageSentByMe.value,
        });
        // watchEffect(() => {
        //   TUICallKitServer.setLanguage(lang.value);
        // })
        function toggleMinimize() {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
            index_1.TUICallKitServer.toggleMinimize();
        }
        function toggleFullscreen() {
            var elem = document.getElementById("tui-call-kit-id");
            if (!document.fullscreenElement) {
                elem.requestFullscreen().catch(function (err) {
                    alert("Error attempting to enable fullscreen mode: ".concat(err.message, " (").concat(err.name, ")"));
                });
            }
            else {
                document.exitFullscreen();
            }
        }
        return function (_ctx, _cache) {
            return ((0, vue_2.unref)(store_1.status) !== (0, vue_2.unref)(constants_1.STATUS).IDLE)
                ? (0, vue_2.withDirectives)(((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                    (0, vue_2.createElementVNode)("div", _hoisted_2, [
                        ((0, vue_2.unref)(store_1.status) !== (0, vue_2.unref)(constants_1.STATUS).IDLE &&
                            (0, vue_2.unref)(allowedMinimized) === true &&
                            (0, vue_2.unref)(store_1.status) !== (0, vue_2.unref)(constants_1.STATUS).DIALING_C2C &&
                            (0, vue_2.unref)(store_1.status) !== (0, vue_2.unref)(constants_1.STATUS).DIALING_GROUP &&
                            (0, vue_2.unref)(store_1.status) !== (0, vue_2.unref)(constants_1.STATUS).BE_INVITED)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                key: 0,
                                class: "minimize",
                                onClick: toggleMinimize
                            }, [
                                (0, vue_2.createVNode)(minimize_vue_1.default)
                            ]))
                            : (0, vue_2.createCommentVNode)("", true),
                        ((0, vue_2.unref)(allowedFullScreen) === true)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                key: 1,
                                class: "minimize",
                                onClick: toggleFullscreen
                            }, [
                                (0, vue_2.createVNode)(fullScreen_vue_1.default)
                            ]))
                            : (0, vue_2.createCommentVNode)("", true)
                    ]),
                    ((0, vue_2.unref)(store_1.status) === (0, vue_2.unref)(constants_1.STATUS).BE_INVITED ||
                        (0, vue_2.unref)(store_1.status) === (0, vue_2.unref)(constants_1.STATUS).DIALING_C2C ||
                        (0, vue_2.unref)(store_1.status) === (0, vue_2.unref)(constants_1.STATUS).CALLING_C2C_AUDIO)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(Dialing_vue_1.default, { key: 0 }))
                        : (0, vue_2.createCommentVNode)("", true),
                    ((0, vue_2.unref)(store_1.status) === (0, vue_2.unref)(constants_1.STATUS).CALLING_C2C_VIDEO)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(Calling_C2CVideo_vue_1.default, { key: 1 }))
                        : (0, vue_2.createCommentVNode)("", true),
                    ((0, vue_2.unref)(store_1.status) === (0, vue_2.unref)(constants_1.STATUS).DIALING_GROUP ||
                        (0, vue_2.unref)(store_1.status) === (0, vue_2.unref)(constants_1.STATUS).CALLING_GROUP_AUDIO ||
                        (0, vue_2.unref)(store_1.status) === (0, vue_2.unref)(constants_1.STATUS).CALLING_GROUP_VIDEO)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(Calling_Group_vue_1.default, { key: 2 }))
                        : (0, vue_2.createCommentVNode)("", true),
                    ((0, vue_2.unref)(store_1.status) !== (0, vue_2.unref)(constants_1.STATUS).IDLE)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(ControlPanel_vue_1.default, { key: 3 }))
                        : (0, vue_2.createCommentVNode)("", true)
                ], 512)), [
                    [vue_2.vShow, !(0, vue_2.unref)(store_1.isMinimized)]
                ])
                : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
