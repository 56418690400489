"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.t = exports.setLanguage = exports.changeIsMinimized = exports.updateProfile = exports.updateRemoteVolumeMap = exports.changeRemoteDeviceByUserID = exports.removeRemoteListByUserID = exports.addRemoteListByUserID = exports.changeRemoteList = exports.changeDialingInfo = exports.changeIsFromGroup = exports.changeCallType = exports.changeStatus = exports.isMinimized = exports.remoteList = exports.isFromGroup = exports.callType = exports.dialingInfo = exports.profile = exports.status = void 0;
var vue_1 = require("vue");
var timer_1 = require("../utils/timer");
var constants_1 = require("../constants");
var tuicall_engine_webrtc_1 = require("tuicall-engine-webrtc");
var index_1 = require("../index");
var index_2 = require("../locales/index");
var lang = (0, vue_1.ref)("zh-cn");
var status = (0, vue_1.ref)(constants_1.STATUS.IDLE);
exports.status = status;
var isMinimized = (0, vue_1.ref)(false);
exports.isMinimized = isMinimized;
var dialingInfo = (0, vue_1.ref)("waiting");
exports.dialingInfo = dialingInfo;
var callType = (0, vue_1.ref)();
exports.callType = callType;
var isFromGroup = (0, vue_1.ref)(false);
exports.isFromGroup = isFromGroup;
var remoteList = (0, vue_1.ref)([]);
exports.remoteList = remoteList;
var profile = (0, vue_1.ref)({
    userID: "not login",
    isEntered: true,
    microphone: true,
    camera: true,
    volume: 0
});
exports.profile = profile;
(0, vue_1.watch)(timer_1.timerString, function () {
    changeDialingInfo(timer_1.timerString.value);
});
(0, vue_1.watch)(status, function () {
    if (status.value === constants_1.STATUS.BE_INVITED) {
        changeDialingInfo(callType.value === constants_1.CALL_TYPE_STRING.AUDIO ? t('start-voice-call') : t('start-video-call'));
    }
    if (status.value.split('-')[0] === "dialing") {
        changeDialingInfo(t('waiting'));
    }
    if (status.value.split('-')[0] === "calling") {
        changeDialingInfo("");
        (0, timer_1.timerStart)();
    }
    if (status.value === constants_1.STATUS.IDLE) {
        profile.value.microphone = true;
        profile.value.camera = true;
        isMinimized.value = false;
        changeDialingInfo("");
        changeRemoteList([]);
        index_1.TUICallKitServer.afterCalling && index_1.TUICallKitServer.afterCalling();
    }
});
function setLanguage(language) {
    lang.value = language;
}
exports.setLanguage = setLanguage;
function t(key) {
    var _a;
    for (var langKey in index_2.default) {
        if (langKey === lang.value) {
            var currentLanguage = index_2.default[langKey];
            for (var sentenceKey in currentLanguage) {
                if (sentenceKey === key) {
                    return currentLanguage[sentenceKey];
                }
            }
        }
    }
    var enString = (_a = key['en']) === null || _a === void 0 ? void 0 : _a.key;
    console.error("translation is not found: ", key);
    return enString;
}
exports.t = t;
function changeStatus(newValue, reason, timeout) {
    if (timeout === void 0) { timeout = 0; }
    console.log("TUICallKit Status: " + newValue);
    if (reason !== constants_1.CHANGE_STATUS_REASON.CALL_TYPE_CHANGED) {
        console.log("TUICallKit timerClear");
        (0, timer_1.timerClear)();
    }
    switch (reason) {
        case constants_1.CHANGE_STATUS_REASON.REJECT:
            changeDialingInfo("".concat(t('be-rejected')).concat(callType.value === constants_1.CALL_TYPE_STRING.AUDIO ? t('voice-call-end') : t('video-call-end')));
            break;
        case constants_1.CHANGE_STATUS_REASON.NO_RESPONSE:
            changeDialingInfo("".concat(t('be-no-response')).concat(callType.value === constants_1.CALL_TYPE_STRING.AUDIO ? t('voice-call-end') : t('video-call-end')));
            break;
        case constants_1.CHANGE_STATUS_REASON.LINE_BUSY:
            changeDialingInfo("".concat(t('be-line-busy')).concat(callType.value === constants_1.CALL_TYPE_STRING.AUDIO ? t('voice-call-end') : t('video-call-end')));
            break;
        case constants_1.CHANGE_STATUS_REASON.CALLING_CANCEL:
            changeDialingInfo(t('be-canceled'));
            break;
        case constants_1.CHANGE_STATUS_REASON.CALLING_TIMEOUT:
            changeDialingInfo(t('timeout'));
            break;
        default:
            changeDialingInfo("");
            break;
    }
    setTimeout(function () {
        index_1.TUICallKitServer.statusChanged && index_1.TUICallKitServer.statusChanged({ oldStatus: status.value, newStatus: newValue });
        status.value = newValue;
    }, timeout);
}
exports.changeStatus = changeStatus;
function addRemoteListByUserID(userID) {
    var isExisted = remoteList.value.findIndex(function (item) { return item.userID === userID; });
    if (isExisted >= 0) {
        remoteList.value[isExisted].isEntered = true;
    }
    else {
        changeRemoteList(__spreadArray(__spreadArray([], remoteList.value, true), [{
                userID: userID,
                isEntered: true,
            }], false));
    }
}
exports.addRemoteListByUserID = addRemoteListByUserID;
function removeRemoteListByUserID(userID) {
    var isExisted = remoteList.value.findIndex(function (item) { return item.userID === userID; });
    if (isExisted >= 0) {
        var resArray = remoteList.value;
        resArray.splice(isExisted, 1);
        changeRemoteList(__spreadArray([], resArray, true));
    }
    return remoteList.value.length;
}
exports.removeRemoteListByUserID = removeRemoteListByUserID;
function changeRemoteDeviceByUserID(userID, deviceType, value) {
    var isExisted = remoteList.value.findIndex(function (item) { return item.userID === userID; });
    if (isExisted >= 0) {
        if (deviceType === constants_1.CALL_TYPE_STRING.VIDEO)
            remoteList.value[isExisted].camera = value;
        else if (deviceType === constants_1.CALL_TYPE_STRING.AUDIO)
            remoteList.value[isExisted].microphone = value;
    }
}
exports.changeRemoteDeviceByUserID = changeRemoteDeviceByUserID;
function updateRemoteVolumeMap(volumeMap) {
    volumeMap.forEach(function (item) {
        var userId = item.userId, audioVolume = item.audioVolume;
        if (userId === profile.value.userID) {
            profile.value.volume = audioVolume;
            return;
        }
        var isExisted = remoteList.value.findIndex(function (item) { return item.userID === userId; });
        if (isExisted >= 0) {
            remoteList.value[isExisted].volume = audioVolume;
        }
    });
}
exports.updateRemoteVolumeMap = updateRemoteVolumeMap;
function changeDialingInfo(newValue) {
    dialingInfo.value = newValue;
}
exports.changeDialingInfo = changeDialingInfo;
function changeCallType(newValue) {
    // change call type
    switch (newValue) {
        case tuicall_engine_webrtc_1.TUICallType.AUDIO_CALL:
            callType.value = constants_1.CALL_TYPE_STRING.AUDIO;
            break;
        case tuicall_engine_webrtc_1.TUICallType.VIDEO_CALL:
            callType.value = constants_1.CALL_TYPE_STRING.VIDEO;
            break;
        default:
            console.error("TUICallKit changeCallType: unknown call type value ", newValue);
            return;
    }
    // change status
    if (status.value === constants_1.STATUS.CALLING_C2C_VIDEO && callType.value === constants_1.CALL_TYPE_STRING.AUDIO) {
        changeStatus(constants_1.STATUS.CALLING_C2C_AUDIO, constants_1.CHANGE_STATUS_REASON.CALL_TYPE_CHANGED);
    }
    if (status.value === constants_1.STATUS.CALLING_C2C_AUDIO && callType.value === constants_1.CALL_TYPE_STRING.VIDEO) {
        changeStatus(constants_1.STATUS.CALLING_C2C_VIDEO, constants_1.CHANGE_STATUS_REASON.CALL_TYPE_CHANGED);
    }
    if (status.value === constants_1.STATUS.BE_INVITED) {
        changeDialingInfo(callType.value === constants_1.CALL_TYPE_STRING.AUDIO ? t('start-voice-call') : t('start-video-call'));
    }
}
exports.changeCallType = changeCallType;
function changeIsFromGroup(newValue) {
    isFromGroup.value = newValue;
}
exports.changeIsFromGroup = changeIsFromGroup;
function changeRemoteList(newValue) {
    remoteList.value = newValue;
}
exports.changeRemoteList = changeRemoteList;
function updateProfile(newValue) {
    profile.value = newValue;
}
exports.updateProfile = updateProfile;
function changeIsMinimized(newValue) {
    isMinimized.value = newValue;
}
exports.changeIsMinimized = changeIsMinimized;
